import React from 'react';

import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  ListItemText,
} from '@mui/material'

import SkillComp from '../../Components/SkillComp';

import scketchCasa from '../../assets/images/03D05.png';
import SmallTitle from '../../Components/SmallTitle';
import Title from '../../Components/Title';

const RegenerativeDesign = () => {
  return (
    <>
      <SkillComp>
        <Grid className='left'>
          <Box
            sx={{
              display: 'flex',
              height: { md: '100%', sm: '300px', xs: '180px' },
              backgroundImage: `url(${scketchCasa})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPositionX: '40%',
              backgroundPositionY: '50%'
            }}
          />
        </Grid>
        <Grid
          className='right'
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}

            sx={{
              margin: {
                xs: '1rem 2rem 5rem 2rem',
                sm: '1rem 2rem 5rem 2rem',
                md: '1rem 20% 5rem 20%'
              }
            }}
            // paddingTop={'3rem'}
            textAlign={'justify'}
          >
            <SmallTitle >
              Regenerative Design
            </SmallTitle>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Second Place - The Lori Villebrun Memorial Design Award
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              The competition addresses the issues with First Nation Housing.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Every year SAIT Architectural Technologies students offer new ideas to this problem. Our project intended to connect the traditional patterns with the house design to make it meaningful. We used Alberta's iconic landscape of the mountains to shape the roof, with the addition of a wood structure with the beading pattern from indigenous clothing and accessories. The house follows the sun path, with the entrance to the East, and living space to the West. The bedrooms are located in the basement in connection with the warmth of the Earth.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Regenerative design incorporates ancient knowledge to bring back the connection between humans and place. It allows for communities to self organize and develop, without preconceived ideas to achieve a particular goal.
            </Typography>
          </Box>
        </Grid>
      </SkillComp>
    </>
  );
};

export default RegenerativeDesign;
