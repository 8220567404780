
import {
  Box,
  Grid,
  TextField,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';

import { useState } from 'react';
import validator from 'validator';

const ContactForm = () => {

  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // validators-----
  const requiredString = (e) => {
    return (e.currentTarget.value.toString().trim().length === 0);
  };

  const isValidEmail = (e) => {
    console.log(e.currentTarget.value);
    if (!validator.isEmail(e.currentTarget.value)) {
      setErrorEmail(true);
      return;
    }

    setErrorEmail(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // check if there's validation errors
    if (errorMessage || errorName || errorEmail) {
      return;
    }

    const allData = {
      name: data.get('name'),
      email: data.get('email'),
      message: data.get('message'),
    };

    //88c50a01-fda4-40e4-b0e7-3bd4b59e7932
    window.Email.send({
      SecureToken: "88c50a01-fda4-40e4-b0e7-3bd4b59e7932",
      To: 'rafakiki@gmail.com',
      // To: 'jeison.santiago@gmail.com',
      // To: `${values.email}`,
      // To: 'info@4elements.eco',
      // From: `${values.email}`,
      From: 'jeison.santiago@gmail.com',
      Subject: `From ${allData.name} email: ${allData.email}`,
      Body:
        `${allData.name}<br />
                     Email:${allData.email}<br />
                     Message:<br />${allData.message}`,
    }).then(message => {
      if (message === 'OK') {
        // console.log('Your message has been sent');
        setOpen(true);
        document.querySelector("#name").value = '';
        document.querySelector("#email").value = '';
        document.querySelector("#message").value = '';

      } else {
        console.log(message);
        // setModalMessage(message);
        // handleShow();
      }
    });

    console.log({
      name: data.get('name'),
      email: data.get('email'),
      message: data.get('message'),
    });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Message has been sent!
        </Alert>
      </Snackbar>

      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: '3rem' }}>
        <Grid
          display={'flex'}
          // justifyContent={'center'}
          // alignContent={'center'}
          flexDirection={'column'}
          container
          rowSpacing={2}

          xl={6}
          md={10}
          xs={11}
          margin={'0 auto'}
        >
          <Grid item>
            <TextField
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              // autoFocus
              variant='standard'
              onChange={(e) => {
                setErrorName(requiredString(e));
              }}
              error={errorName}
              helperText={(errorName) ? 'Name required' : ''}
            />

          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              variant='standard'

              onChange={isValidEmail}
              error={errorEmail}
              helperText={(errorEmail) ? 'Valid email required' : ''}
            />
          </Grid>
          <Grid item>
            <  TextField


              minRows={3}
              multiline
              fullWidth
              required
              label="Message"
              name="message"
              id="message"
              type='text'
              variant='standard'

              onChange={(e) => {
                setErrorMessage(requiredString(e));
              }}
              error={errorMessage}
              helperText={(errorMessage) ? 'Message required' : ''}
            />
          </Grid>
          <Grid
            item
            width={'100%'}
            display={'flex'}
            sx={{
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              sx={{
                marginTop: '1rem',
              }}

            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ContactForm;