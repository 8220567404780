import React from 'react';

import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';

import Title from '../Title';
import SkillComp from '../../Components/SkillComp';
import imageDraw from '../../assets/images/draw.png';

const Skills = () => {
  return (
    <>
      <SkillComp
      // backgroundColorRight={'primary.dark'}
      >
        <Grid
          className='left'
        >
          <Grid
            // item
            display={'flex'}
            height={'100%'}
            sx={{
              flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
              alignItems: { xs: 'center', sm: 'center', md: 'center', lg: 'unset' }
            }}
          // alignItems={'center'}
          >
            <Grid
              width={'100%'}
              sx={{
                height: { md: '100%', sm: '300px', xs: '180px' },
                backgroundImage: `linear-gradient(to top, rgba(31,80,68,0.8), rgba(31,80,68,0.8)), url(${imageDraw})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                // backgroundPositionY: '40%',
                color: 'primary.main',
              }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className='right'
        >
          <Grid
            // width={'50%'}
            sx={{
              height: '100%',

              backgroundColor: 'primary.dark',
              color: 'primary.main',
              padding: '4rem 0rem',
            }}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Title
              textColor={'#fff'}
            >
              Skills
            </Title>

            <Box
              component={'div'}
              display={'inherit'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              // margin={'2rem'}
              color={'#fff'}
            >
              <Typography>
                I have a very diverse background, learning from different industries.
              </Typography>
              <Typography>
                If you want to know more about my work experience, check out my LinkedIn profile:
              </Typography>
              <Box
                display={'inherit'}
                columnGap={'2rem'}
              >
                <Button
                  variant="outlined"
                  target={'_blank'}
                  href={`${process.env.PUBLIC_URL}/Rafaela_Santiago.pdf`}
                  style={{
                    border: '1px solid white',
                    color: '#fff',
                    // borderRadius:'25%',
                    margin: '2rem 0rem',
                  }}
                >
                  Resume
                </Button>
                {/* <Button
                  variant="outlined"
                  target={'_blank'}
                  href={`${process.env.PUBLIC_URL}/Portfolio.pdf`}
                  style={{
                    border: '1px solid white',
                    color: '#fff',
                    // borderRadius:'25%',
                    margin: '2rem 0rem',
                  }}
                >
                  PORTFOLIO
                </Button> */}
              </Box>
            </Box>

          </Grid>
        </Grid>
      </SkillComp>
    </>
  );
};
export default Skills;
