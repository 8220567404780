import React from 'react';

import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  ListItemText,
} from '@mui/material'

import Title from '../Title';

import imageRafa from '../../assets/images/rafa.jpg';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <Container
        maxWidth='100%'
        disableGutters={true}
        style={{
          height: '100%',
          // maxHeight: '800px',
          // marginLeft: '12px',
          // marginRight: '0px',
        }}
      >
        <Grid
          item
          display={'flex'}
          height={'100%'}
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          }}

        >
          <Grid
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}

            sx={{
              width: { xs: '100%', sm: '100%', md: '50%' },
              marginBottom:'2rem'
            }}

          >
            <Box
              sx={{
                marginTop: '3rem',
                width: { xs: '80%', sm: '80%', md: '60%', lg: '50%' }
              }}
            >
              <Title>
                About
              </Title>
              <Typography
                fontSize={18}
                // color={'rgb(121, 148, 130)'}
                color={'#003b45'}
                marginBottom={'2rem'}

              >
                A small town girl with big dreams.
              </Typography>
              <Box
                textAlign={'justify'}
              >

                <Typography
                  margin={'2rem 0'}
                  variant={'p'}
                >
                  In my point of view, all problems are opportunities to create significant results. Currently, I support builders and consultants with energy efficiency and sustainable buildings. I have had the privilege to have worked in many cutting edge design projects, both in Brazil and in Canada. I have a passion for sustainability, art, and technology.
                </Typography>
                <Typography
                  variant={'p'}
                >
                  What I normally do:
                </Typography>
                <List >
                  <ListItemText>
                    Brainstorming and potential seeking
                  </ListItemText>
                  <ListItemText>
                    Breaking down complex problems
                  </ListItemText>
                  <ListItemText>
                    Constantly learning multiple subjects
                  </ListItemText>
                </List>
                <Typography
                  margin={'2rem 0'}
                  variant={'p'}
                >
                  I have a diverse background, ranging from customer service to project management. I love being a part of a community and volunteering for a good cause.
                </Typography>
                <Typography
                  margin={'2rem 0'}
                  variant={'p'}
                >
                  Specialties: sustainable architecture, green building rating systems, energy efficiency, LEED, BIM, architectural technologies, construction drawings, regenerative design.
                </Typography>
                <Typography
                  margin={'2rem 0'}
                  variant={'p'}
                >
                  I also have a website in Portuguese where I share information about sustainable construction. Click below to check it out:
                </Typography>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
              >
                <Button
                  variant="outlined"
                  style={{
                    // border: '1px solid primary.main',
                    // color: 'primary.main',
                    // borderRadius:'25%',
                    margin: '2rem 0rem',
                  }}
                  href={'https://cicloconstrutivo.wixsite.com/cicloconstrutivo'}
                  target={'_blank'}
                >

                  Ciclo Construtivo

                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            sx={{
              backgroundImage: `linear-gradient(to top, rgba(121,148,130,0.0), rgba(121,148,130,0.4)), url(${imageRafa})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: { xs: '100%', sm: '100%', md: '50%' },
            }}

          >
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default About;
