import React from 'react';

import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  ListItemText,
} from '@mui/material'

import SkillComp from '../../Components/SkillComp';

import spLixao from '../../assets/images/splixao.jpg';
import SmallTitle from '../../Components/SmallTitle';

const UrbanPlanning = () => {
  return (
    <>
      <SkillComp>
        <Grid
          className='left'
        >
          <Box
            height={'100%'}
            display={'flex'}
            sx={{
              backgroundImage: `url(${spLixao})`,
              backgroundRepeat: 'no-repeat',
              // backgroundSize: '70%',
              backgroundSize: 'contains',
              backgroundPosition: 'center center',

              // backgroundSize: 'cover',

              height: { md: '100%', sm: '300px', xs: '180px' },

              backgroundPositionX: '40%',
              backgroundPositionY: '50%'
            }}
          />
        </Grid>
        <Grid className='right'>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}

            height={'100%'}
            textAlign={'justify'}

            // sx={{
            //   margin: { xs: 'auto 2rem', sm: 'auto 10%', md: 'auto 20%' }
            // }}
            sx={{
              margin: {
                xs: '1rem 2rem 5rem 2rem',
                sm: '1rem 2rem 5rem 2rem',
                md: '1rem 20% 5rem 20%'
              }
            }}
          >
            <SmallTitle>
              Urban Planning
            </SmallTitle>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              The purpose of this project was to use the airport to promote economic development and strengthen the city's identity with air travel.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              The new passenger terminal would encourage low cost air companies with smaller routes. It also repurposes local infrastructure currently used only for private or military uses. The new masterplan adds a new industrial district with room to expand close to the highway, sewage and water treatment stations, a recycling center, train and bus stations, and future private terminal and flight school. All the proposed uses and buildings follow city's bylaws and national regulations.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Working with identity of place and nestedness is a crucial part of a successful project.
            </Typography>
          </Box>
        </Grid>
      </SkillComp>
    </>
  );
};

export default UrbanPlanning;
