
import { Link } from '@mui/material';
import {
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';

import { LinkedIn, Facebook, Instagram } from '@mui/icons-material';

import ContactForm from '../../Components/ContactForm';
import Title from '../../Components/Title';

const Contact = () => {


  return (
    <>
      <Container
        maxWidth='xl'
      >
        <Grid
          display={'flex'}
          // height={'20%'}
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            margin: { xs: 'auto 0rem', sm: 'auto 10%', md: 'auto', lg: 'auto', xl: 'auto' }
          }}
        >
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            textAlign={'center'}
            marginTop={'3rem'}
            lg={6}
            md={12}
            sm={12}
          >
            {/* <Typography variant='h3'>
              Contact
            </Typography> */}
            <Title>
              Contact
            </Title>
            <Typography variant='p' sx={{
              margin: '1rem 1rem'
            }}>
              Get in touch if you need help, want to collaborate, or want to know more.
            </Typography>
            <Typography variant='p'>
              Calgary, AB
            </Typography>
            <Link href={'mailto:rmo.sant@gmail.com'} sx={{marginBottom:'1rem'}}>
              <Typography variant='p'>
                rmo.sant@gmail.com
              </Typography>
            </Link>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '6px' }}>
              <IconButton target={'_blank'} href={'https://www.linkedin.com/in/rafamos/'}>
                <LinkedIn fontSize='large' sx={{ color: 'primary.dark' }} />
              </IconButton>

              <IconButton target={'_blank'} href={'https://www.facebook.com/rmo.sant/'}>
                <Facebook fontSize='large' sx={{ color: 'primary.dark' }} />
              </IconButton>

              <IconButton target={'_blank'} href={'https://www.instagram.com/rmo.sant/'}>
                <Instagram fontSize='large' sx={{ color: 'primary.dark' }} />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default Contact;
