import { useState, useEffect } from 'react';

import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  Link,
  ListItem,
  Button,
  ListItemText,
  Card,
  CardContent,
  CssBaseline,
} from '@mui/material'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Title from '../../Components/Title';

import SkillComp from '../../Components/SkillComp';
import techDraw from '../../assets/images/techdraw.png';

import CardExperience from '../../Components/CardExperience/cardExperience';

import experience from '../../data/experience.json';

const Experience = () => {

  const theme = useTheme();
  const layoutSmall = useMediaQuery(
    theme.breakpoints.up('sm') &&
    theme.breakpoints.up('md')
  );

  return (
    <>
      <Grid
        display={'flex'}
        flexDirection={'column'}
      >
        <Grid
          item
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          // marginBottom={'1rem'}
          marginTop={'2rem'}
          sx={{
            // backgroundColor: '#2c1a2d',
            height: { xs: '100px', sm: '150px', md: '150px' },
          }}
        >
          <Title
            // textColor={'white'}
          >
            Experience
          </Title>
        </Grid>

        {layoutSmall &&
          <Grid
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'row'}
            lg={8}
            margin={'auto auto'}
          >
            <Grid
              md={8} lg={8}
              justifyContent={'end'}
            >
              {
                experience.map(exp => {
                  if (exp.index % 2 !== 0) {
                    return (
                      <CardExperience
                        dates={exp.date}
                        position={exp.position}
                        companyName={exp.companyName}
                        companyLink={exp.companyLink}
                        sx={{
                          backgroundColor:'primary.dark',
                          minWidth: 200,
                          maxWidth: 400,
                          marginTop: '5rem',
                          marginBottom: '5rem',
                          position: 'relative',
                          minHeight: '170px',

                          display:'flex',
                          flexDirection:'column',
                          justifyContent:'center',
                        }}
                      />
                    )
                  } else {
                    return undefined;
                  }
                })
              }
            </Grid>
            <Grid md={2} lg={2}>
            </Grid>
            <Grid md={8} lg={8} marginTop={'85px'}>
              {
                experience.map(exp => {
                  if (exp.index % 2 === 0) {

                    return (
                      <CardExperience
                        dates={exp.date}
                        position={exp.position}
                        companyName={exp.companyName}
                        companyLink={exp.companyLink}
                        sx={{
                          backgroundColor:'primary.dark',
                          minWidth: 275,
                          maxWidth: 400,
                          marginTop: '5rem',
                          marginBottom: '5rem',
                          position: 'relative',
                          minHeight: '170px',

                          display:'flex',
                          flexDirection:'column',
                          justifyContent:'center',
                        }}
                      />
                    )
                  } else {
                    return undefined;
                  }
                })
              }
            </Grid>
          </Grid>
        }
        {
          !layoutSmall &&
          <Grid
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            lg={8}
            margin={'auto auto'}
          >{
              experience.map(exp => {
                return (
                  <CardExperience
                    dates={exp.date}
                    position={exp.position}
                    companyName={exp.companyName}
                    companyLink={exp.companyLink}
                    sx={{
                      backgroundColor:'primary.dark',
                      margin: '1rem auto',
                      minWidth: '300px',
                      maxWidth: '300px',
                      
                      // marginTop: '1rem',
                      // marginBottom: '1rem',
                    }}
                  />
                )
              })}
          </Grid>
        }

        
      </Grid>
    </>
  )
}
export default Experience;
