import React from 'react';

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Fade
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import {
  Slide,
  IconButton,
} from '@mui/material'

import { scroller } from 'react-scroll';

import { LinkedIn, Facebook, Instagram } from '@mui/icons-material';

import logo from '../../assets/images/logo.png';

const pages = [
  {
    name: 'Home',
    goElement: 'goHome',
  },
  {
    name: 'About',
    goElement: 'goAbout',
  },
  {
    name: 'Skills',
    goElement: 'goSkills',
  },
  {
    name: 'Experience',
    goElement: 'goExperience',
  },
  {
    name: 'Contact',
    goElement: 'goContact',
  },
];

// override config from Slide from up to left direction
const SlideJ = React.forwardRef((props, ref) => <Slide direction='left' {...props} ref={ref} />);

const ResponsiveMenu = () => {

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollTo = (elementName) => {
    scroller.scrollTo(elementName, {
      duration: 800,
      delay: 100,
      smooth: 'easeInOutQuart',
      offset: -56,
    });

    handleCloseNavMenu();
  }

  return (
    <Fade
      in
      sx={{ transitransitionDelay: 1000 }}
    >
      <AppBar
        position="fixed"
      >
        <Container
          maxWidth="xl"
        >
          <Toolbar disableGutters >
            {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            LOGO
          </Typography> */}



            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                keepMounted
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                TransitionComponent={SlideJ}
                sx={{
                  top: '20px',
                  left: '-16px',
                  right: '-16px',
                  display: { xs: 'block', md: 'none' },
                  transform: '0ms scale(1,1)',

                }}
                BackdropProps={{
                  style: { height: '100vh' }
                }}
                PaperProps={{
                  style: {
                    // left:'50%',
                    height: '100vh',
                    width: '60%',
                    borderRadius: '0px',

                  }
                }}
              >

                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={() => { scrollTo(page.goElement); }}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '6px' }}>
                  <IconButton target={'_blank'} href={'https://www.linkedin.com/in/rafamos/'}>
                    <LinkedIn fontSize='medium' sx={{ color: 'primary.dark' }} />
                  </IconButton>

                  <IconButton target={'_blank'} href={'https://www.facebook.com/rmo.sant/'}>
                    <Facebook fontSize='medium' sx={{ color: 'primary.dark' }} />
                  </IconButton>

                  <IconButton target={'_blank'} href={'https://www.instagram.com/rmo.sant/'}>
                    <Instagram fontSize='medium' sx={{ color: 'primary.dark' }} />
                  </IconButton>
                </Box>
              </Menu>
            </Box>
            <Box
              display={'flex'}
              sx={{
                flexGrow: { xs: '0', md: '1' },
              }}
            >
              <img
                src={logo} alt='#'
                width={45}
              />
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => { scrollTo(page.goElement); }}
                  sx={{ my: 2, color: 'inherit', display: 'block' }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar >
    </Fade>
  );
};
export default ResponsiveMenu;
