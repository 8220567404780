
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { KeyboardReturnTwoTone } from '@mui/icons-material';

function Title(props) {
  return (
    <Typography
      variant="h2"

      color={props.textColor ? props.textColor : 'primary.main'}
      margin={props.margin ? props.margin:'0px'}
      // fontSize={'4.5rem'}

      sx={{
        fontSize:{xs:'3rem',md:'4.5rem'}
      }}
    >
      {props.leftArrow &&
        <KeyboardReturnTwoTone
          fontSize='large'
          sx={{
            transform:'scaleY(-1) rotate(90deg)'
          }}
        />
      }
      {props.children}
      {props.rightArrow &&
        <KeyboardReturnTwoTone
          fontSize='large'
          sx={{
            transform:'rotate(-90deg)',
          }}
        />
      }

    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;