import React, { useEffect, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  ListItemText,
  CssBaseline
} from '@mui/material'

import Title from '../Title';

import { color, margin } from '@mui/system';

const SkillComp = (props) => {

  const [leftContent, setLeftContent] = useState("");
  const [rightContent, setRightContent] = useState("");

  useEffect(() => {
    props.children.forEach(item => {
      if (item.props.className === 'left') {
        setLeftContent(item.props.children);
      } else if (item.props.className === 'right') {
        setRightContent(item.props.children);
      }
    });
  }, [props.children]);

  return (
    <>
      <Container
        maxWidth="100%"
        disableGutters={true}
      >
        <Grid
          // item
          display={'flex'}
          // height={'100%'}
          // height={'100vh'}
          sx={{
            flexDirection: { xs: (props.reverse) ? 'column-reverse' : 'column', sm: (props.reverse) ? 'column-reverse' : 'column', md: 'row' },
          }}
        >
          <Grid
            sx={{
              width: { xs: '100%', sm: '100%', md: '50%' },
              backgroundColor: (props.backgroundColorLeft) ? props.backgroundColorLeft : 'transparent',
            }}
          >
            {leftContent}
          </Grid>
          <Grid
            sx={{
              width: { xs: '100%', sm: '100%', md: '50%' },
              backgroundColor: (props.backgroundColorRight) ? props.backgroundColorRight : 'transparent',
            }}
          >
            {rightContent}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default SkillComp;
