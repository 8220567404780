import {
  Container,
  Box,
  Grid,
  Typography,
  IconButton,
  Link
} from '@mui/material';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      {new Date().getFullYear()}
      {` Rafaela Santiago - All Rights Reserved`}
      {/* <Link color="inherit" href="https://mui.com/"> */}
      {/* </Link>{' '} */}

      {'.'}
    </Typography>
  );
}

const Footer = () => {
  return (
    <Copyright
      sx={{ margin: '5rem 0rem 2rem 0rem' }}
    />
  )
}

export default Footer;