
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { KeyboardReturnTwoTone } from '@mui/icons-material';

function SmallTitle(props) {
  return (
    <Typography
      variant="h2"

      color={props.textColor ? props.textColor : "primary.main"}
      // fontSize={'4.5rem'}

      sx={{
        // fontSize:'2.5rem',
        fontSize:{xs:'2rem',sm:'2.5rem'},
        margin:'2rem 0rem 1rem 0rem'
      }}
    >
      {props.leftArrow &&
        <KeyboardReturnTwoTone
          fontSize='large'
          sx={{
            transform:'scaleY(-1) rotate(90deg)'
          }}
        />
      }
      {props.children}
      {props.rightArrow &&
        <KeyboardReturnTwoTone
          fontSize='large'
          sx={{
            transform:'rotate(-90deg)',
          }}
        />
      }

    </Typography>
  );
}

SmallTitle.propTypes = {
  children: PropTypes.node,
};

export default SmallTitle;