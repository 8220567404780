import React from 'react';

import {
  Box,
  Container,
  Grid,
} from '@mui/material';

import imageTeto from '../../assets/images/teto.JPG';
import logoRafa from '../../assets/images/rafaname.png';

const Intro = () => {
  return (
    <Container
      maxWidth='0'
      disableGutters
      sx={{
        // backgroundColor: '#272e31',
        backgroundColor: 'primary.dark',
        // height: {xs:'100vh',sm:'100vh',md:'660px',lg:'660px'},
        // maxHeight: '660px',
        height: '100vh',
        // marginLeft: '0px',
        // marginRight: '0px',
        // paddingTop: '68px',
      }}
    >
      <Grid
        height={'100%'}
        display={'flex'}
        width={'100%'}
        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Grid
          sx={{
            height: '100%',
            backgroundImage: `url(${imageTeto})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            width: { lg: '50%', md: '100%', sm: '100%' }
          }}

        >
        </Grid>
        <Grid
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            width: { lg: '50%', md: '100%', sm: '100%' },
            height: { xs: '250px', sm: '100%' },
            textAlign: 'center'
          }}
        >
          <Box
            color={'#fff'}
            sx={{
              backgroundImage: `url(${logoRafa})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              //TODO: RAFAELA LOGO SITE TO SVG NOT PNG !!!
              height: '100%',
              width: '100%',
              // backgroundSize: {sm:'20%',md:'40%'},
              backgroundSize: '45%'
            }}


          >

            {/* <Typography
              variant='h1'
              sx={{
                fontSize: { xs: '4rem', sm: '4rem', md:'6rem' },
                
              }}
            >
              RAFAELA
            </Typography>
            <Typography
              variant='h2'
              sx={{
                fontSize: { xs: '3rem', sm: '3rem', md:'4rem' }
              }}
            >
              SANTIAGO
            </Typography> */}


          </Box>

        </Grid>
      </Grid>
    </Container>
  );
};
export default Intro;
