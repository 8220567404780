import React from 'react';

import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  ListItemText,
} from '@mui/material'

import SkillComp from '../../Components/SkillComp';

import dataImage from '../../assets/images/datavis.png';
import SmallTitle from '../../Components/SmallTitle';

const DataVisualization = () => {
  return (
    <>
      <SkillComp reverse>
        <Grid className='left'>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            // width={'50%'}
            // margin={'auto'}
            height={'100%'}
            textAlign={'justify'}

            // sx={{
            //   margin: { xs: 'auto 2rem', sm: 'auto 10%', md: 'auto 20%' }
            // }}
            sx={{
              margin: { 
                xs: '1rem 2rem 5rem 2rem', 
                sm: '1rem 2rem 5rem 2rem', 
                md: '1rem 20% 5rem 20%' }
            }}
          >
            <SmallTitle>
              Data Visualization and Analisys
            </SmallTitle>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Selecting what data to present, and how to present it, is one of the challenges we face today. It is easy to see the numbers in a spreadsheet, but losing sight of the meaning behind them.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              These grpahs were used for the assessment of potential users for a multi unit residential project. From the government data, we can identify inhabitants' profile and neighbourhood challenges. In this case, even though the data indicated middle class population, a high percentage of homes had no gargabe collection, no sewage connection, and no wheelchair accessible sidewalks. If known beforehand, these issues can be addressed by the design.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Having more information during schematic design discovery phase can save time and money on conflicts during construction. With the increasing demand for energy efficiency and embodied carbon metrics in construction projects, more data is likely going to inform the design. I have constantly used this skill, ranging from structural damage reporting to grant applications, and it is one of my favourite methods of delivering information.
            </Typography>
          </Box>
        </Grid>
        <Grid
          className='right'
        >
          <Box
            height={'100%'}
            display={'flex'}
            sx={{
              backgroundImage: `url(${dataImage})`,
              backgroundRepeat: 'no-repeat',
              // backgroundSize: '70%',
              backgroundSize: 'contain',
              backgroundPosition: 'center center',

              height: {md:'100%',sm:'300px',xs:'180px'},
            }}
          />
        </Grid>
      </SkillComp>
    </>
  );
};

export default DataVisualization;
