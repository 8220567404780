import React from 'react';

import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  Link,
  ListItem,
  Button,
  ListItemText,
} from '@mui/material'

import SkillComp from '../../Components/SkillComp';

import techDraw from '../../assets/images/techdraw.png';
import SmallTitle from '../../Components/SmallTitle';

const TechDrawing = () => {
  return (
    <>
      <SkillComp>
        <Grid
          className='left'
        >
          <Box
            display={'flex'}
            sx={{
              backgroundImage: `url(${techDraw})`,
              backgroundRepeat: 'no-repeat',
              // backgroundSize: '70%',
              backgroundSize: '50%',
              backgroundPosition: 'center center',
              height: { md: '100%', sm: '300px', xs: '180px' },
            }}
          />
        </Grid>
        <Grid className='right'>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}

            height={'100%'}
            textAlign={'justify'}

            // sx={{
            //   margin: { xs: 'auto 2rem', sm: 'auto 10%', md: 'auto 20%' }
            // }}
            sx={{
              margin: {
                xs: '1rem 2rem 5rem 2rem',
                sm: '1rem 2rem 5rem 2rem',
                md: '1rem 20% 5rem 20%'
              },
            }}
          >
            <SmallTitle>
              Technical Drawing
            </SmallTitle>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Technical drawings done for
              <Link
                sx={{ color: 'primary.dark' }} target={'_blank'} href='http://www.sait.ca/'>Southern Alberta Institute of Technology (SAIT)
              </Link>
              Architectural Technologist Program (Building Development).
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              From reference plans from the Canada Mortgage and Housing Corporation (CMHC), we made small changes to update the design. For the building systems class, we drafted the wall assemblies to demonstrate protection from exterior forces and enviromental conditions.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              Similar to the data analysis, a project can look good in a detail, but not be constructible. My education at SAIT combined the theoretical drafting skills with hands on wood frame construction, which allowed me to understand how pieces go together. I also have to thank my instructors for step by step guidance on doing bylaw and building code reviews.
            </Typography>
          </Box>
        </Grid>
      </SkillComp>
    </>
  );
};

export default TechDrawing;
