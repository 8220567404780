import './App.css';

import React, { useEffect } from 'react';

import {
  CssBaseline,
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Element } from 'react-scroll';

import ResponsiveMenu from './Components/ResponsiveMenu';
import Intro from './Components/Intro';
import About from './Components/About';
import RegenerativeDesign from './Pages/RegenerativeDesign';
import SustainableArchitecture from './Pages/SustainableArchitecture';
import UrbanPlanning from './Pages/UrbanPlanning';
import DataVisualization from './Pages/DataVisualization';
import TechDrawing from './Pages/TechDrawing';
import Experience from './Pages/Experience';
import Contact from './Pages/Contact/contact';
import Footer from './Components/Footer';
import Skills from './Components/Skills';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        light: '#2a7c45',
        main: '#2A7C6F',
        dark: '#1f5044',
        // contrastText: '#799482', // text colors!
        focus: '#c1c1c1',
      },
      secondary: {
        light: '#F7FFFA',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      text: {
        // secondary:'#c1c1c1',
        primary: '#3d3d3d',
        secondary: '#3d3d3d',
        disabled: '#3d3d3d',
      },
    },

  });


  // 256670
  return (
    <>
      <CssBaseline />

      <ThemeProvider
        theme={theme}
      >
        <ResponsiveMenu />


        <Element name='goHome'>
          <Intro />
        </Element>

        <Element name='goAbout'>
          <About />
        </Element>


        <Element name='goSkills'>
          <Skills />
        </Element>

        <RegenerativeDesign />
        <SustainableArchitecture />
        <UrbanPlanning />
        <DataVisualization />
        <TechDrawing />

        <Element name='goExperience'>
          <Experience />
        </Element>

        <Element name='goContact'>
          <Contact />
        </Element>

        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
