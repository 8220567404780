import {
  Card,
  CardContent,
  Typography,
  Link,
} from '@mui/material';

const CardExperience = ({ dates, position, companyName, companyLink, sx, aos }) => {
  return (
    <Card
      sx={sx}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="white" gutterBottom>
          {dates}
        </Typography>
        <Typography variant="h6" component="div" color="white">
          {position}
        </Typography>
        {companyLink ?
          <Link
            sx={{
              mb: 1.5,
              color: 'secondary.light'
            }}
            // color="primary.dark"
            target={'_blank'}
            href={companyLink ? companyLink : ""}
          >{companyName}
          </Link>
          :
          <Typography
            color={'#fff'}
          >{companyName}
          </Typography>
        }
      </CardContent>
    </Card>
  )
}

export default CardExperience;

