import React from 'react';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material'

import SkillComp from '../../Components/SkillComp';

import insideCasa from '../../assets/images/inside.jpg';
import SmallTitle from '../../Components/SmallTitle';

const SustainableArchitecture = () => {
  return (
    <>
      <SkillComp reverse>
        <Grid className='left'>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            // width={'60%'}
            // margin={'auto'}
            height={'100%'}
            textAlign={'justify'}
            // sx={{
            //   margin: { xs: 'auto 2rem', sm: 'auto 10%', md: 'auto 20%' }
            // }}
            sx={{
              margin: { 
                xs: '1rem 2rem 5rem 2rem', 
                sm: '1rem 2rem 5rem 2rem', 
                md: '1rem 20% 5rem 20%' }
            }}
          >
            <SmallTitle>
              Sustainable Architecture
            </SmallTitle>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              I help builders and designers in their sustainability journey. Everyone is in a different step along the way, trying to reduce environmental impact and improve the way we build.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem', textAlign: 'left' }}>
              Green certifications / sustainability rating systems documentation:
            </Typography>
            <ul>
              <li>
                Living Building Challenge
              </li>
              <li>
                LEED
              </li>
              <li>
                CHBA Net Zero
              </li>
              <li>
                Passive House
              </li>
              <li>
                ENERGY STAR
              </li>
              <li>
                EnerGuide
              </li>
              <li>
                Built Green
              </li>
              <li>
                Sustainability Tracking, Assessment & Rating System (STARS)
              </li>
            </ul>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              In addition, I provide services of energy modeling, energy cost analysis, carbon emissions and embodied carbon calculations, construction specifications, building science consulting, tips on comfort, indoor air quality, and energy efficiency.
            </Typography>
            <Typography variant={'p'} sx={{ margin: '1rem 0rem' }}>
              I believe we need to go beyond sustainability and create positive impact instead. I also understand that change takes time. Taking the first steps in this journey, and understanding why the change is important, is what will make a difference in the world.
            </Typography>
          </Box>
        </Grid>
        <Grid className='right'>
          <Box
            sx={{
              // height: '100%',
              backgroundImage: `url(${insideCasa})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',

              display:'flex',
              height: {md:'100%',sm:'300px',xs:'180px'},
              
              backgroundPositionX:'40%',
              backgroundPositionY:'50%'
            }}
          />

        </Grid>
      </SkillComp>
    </>
  );
};

export default SustainableArchitecture;
